.tablaServicios{
display: flex;
justify-content: center;
align-items: center;
}

.containerServiceMobile{
    display: none;
}

.containerServiceDesktop {
    width: 95%;
    margin-top: 40px;
    margin-bottom: 40px;
    min-width: 720px;
}

@media (max-width: 768px) {
    .containerServiceDesktop {
        display: none;
    }

    .containerServiceMobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
}
