 .footer{
  background-color: #00748b;
  height: 320px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.footerLeft{
  display: flex;  
  flex-direction: column;
  color: white;
  padding-top: -10px;
  margin-right: 120px;
  height: 189px;
}

.footerLeft h3, .link,.footerRight h3{
  color: white;
  
}

.footerLeft h3{
  margin-bottom: 0;
}

.footerLeft h3 ,.footerRight h3{
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: var(--unnamed-character-spacing-0);
  line-height: 35px;
  font-size: 30px;
} 

.footerLeftLink{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }

.footerLeft .link{
  font-family: 'Red Hat Display', sans-serif;
  font-size: 16px;
  padding-top: 10px;
}

.footerLeft .link:hover{
  color:white;
}

.footerLineCenter{
  background-color: white;
  height: 200px;
  width: 3px;
}

.footerRight{
  height: 189px;
  margin-left: 60px;
}

.footerRight p{
  font-size: 14px;
}

@media (max-width: 767px) {
  .footer {
    height: 420px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;
  }

  .footerLeft{
     height: 150px;
     margin-bottom: 10px;
  }

  .footerLeft h3,.footerRight h3{
    font-weight: bold;
     font-size: 20px;
  }

  .footerLeft .link{
     font-size: 13px;
  }
  
  .footerLineCenter{
    background-color: white;
    height: 3px;
    width: 200px;
  }

  .footerRight{
    margin-left: 0;
  }

  .footerRight h3{
    margin-top: 10px;
  }
  .footerRight p{
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footerLeft h3,.footerRight h3{
    font-size: 22px;
  }

  .footerLeft .link{
     font-size: 22px;
  }
  .footerRight p{
    margin-bottom: 20px;
    font-size: 12px;
  }

}

@media (min-width: 840px) and (max-width: 868px){
  .footer .footerRight p{
    color:white ;
  }
}

@media (min-width: 869px) and (max-width: 1023px) {
  .footer .footerRight p{
    color:white;
  }
}

@media (min-width: 992px) {
  .footerLeft .link{
    font-size: 14px;
  }
  .footerRight p{
    color:white;
  }
} 
