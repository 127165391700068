.custom-container {
  display: flex;
  justify-content: center;
}

.titulo-registracion {
  float: left;
  color: #00748b;
  margin-top: 30px;
}

.text-registracion {
  font-size: 18px;
  color: #000000;
  float: left;
  margin-top: 10px;
  text-align: left;
}

.datos-personales {
  color: #00748b;
  margin-top: auto;
  font-size: 18px;
}

.divider {
  width: 100%;
}

.margin-divider {
  margin-bottom: 30px;
}

.texto-contraseña {
  font-size: 16px;
  text-align: left;
}

.btn-end-form {
  margin-top: 30px;
  margin-bottom: 30px;
}

#containerRegister{
  margin-top: 83px;
}
