.loader-wrapper {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   background: transparent;
   z-index: 10;
   top:100px
}

.loading{
  background: transparent;
}
