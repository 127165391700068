.containerCyclicLine{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
}

.ImgIconsCyclic{
    margin-top: 60px;
    min-width: 234px;
}
.logoPuntosCyclicLine{
    position: absolute;
    top:100px;
    right: 0;
}
.titleClicLine{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: #00748B;
}

.containerCycliLine img{
    position: absolute;
    top:0;
    right: 0px;
}

.containerCyclicLineDetalles{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 710px;
}

.containerTitleText{
    font-display: flex;
    flex-direction: column;
    width: 71%;
    margin-left: 30px;
    
}

.titleDetails{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 23px;
    color:#00637B;
    margin-top: 0;
    
}

.paragraphDetails{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    color: #3c3c3B;
}

.cyclicLineParagraph{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 23px;
    color:#00748B;
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center;
    width: 790px;
}

.condiciones{
    font-family: 'Red Hat Display', sans-serif;
    font-size:16px ;
    color: #707070;
    margin-bottom: 100px;
}

.logoPuntosCyclicLineButton{
    position:absolute;
    bottom: 30px;
    right: -120px;

}

.containerSlider{
    display: none;
}

@media (max-width: 1024px) {
    .logoPuntosCyclicLineButton{
        right: -18px;
   }
}
@media (max-width: 768px) {
    .containerCyclicLine{
        padding-top: 50px;
    }

    .titleClicLine{
        margin-bottom: 50px;
    }

    .cyclicLineParagraph{
        margin: 30px;
        margin-left: 40px;
        margin-bottom: 100px;

    }
    .condiciones{
        margin-left: 10px;
        margin-right: 10px;
    }
    .containerCyclicLineDetalles{
        flex-direction: column;
    }
    .containerSlider{
        display: block;
        width: 300px;
        min-height: 100px;
    }

    .containerCyclicLineDetalleMap{
        display: none;
    }
    

    .titleDetailsMobile{
    color:#00748B;
    }
    
    .containerTitleTextMobile{
        margin-top: 20px;
    }

    .condiciones{
        margin: 40px;
        margin-top: 0px;
    }

 }

@media (max-width: 480px) {
.containerCyclicLine{
    padding-top: 50px;
}

.logoPuntosCyclicLine{
    width: 45px;
    height: 100px;
}

.containerCyclicLineDetallesMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.paragraphDetailsMobile{
    color: #707070;
}
.imgCyclicLineDetallesMobile{
    margin-left: 30px;
    margin-bottom: 20px;
}

.titleClicLine{
    margin-bottom: 60px;
}

.cyclicLineParagraph{
    margin-top: 60px;
    width: 70%;
    font-size: 16px;
}
.condiciones{
   width: 75%; 
}

.logoPuntosCyclicLineButton{
    width: 100px;
    height: 40px;
    right: 30px;
}
}