.containerServicios{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent
}

.containerServiceDetalle{
    margin-top: 2%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.titleServicios{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: #00748B;
}

.paragraphServicios{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.iconsServiciosPuntos{
    position: absolute;
    width: 138px;
    height: 78px;
    top: -20px;
    left: -65%;
}

@media (max-width: 768px) {
    .imageFlor{
        display: none;
    }

    .titleServicios{
        margin-top: 100px;
    }
    .btnAsersorar{
        margin-bottom: 50px;
    }
}

@media (max-width: 480px) {

.containerServicios{
    margin-top: 10px;
}

.paragraphServicios{
    font-size: 16px;
}
}


