 .container_frequently_question{
  margin-top: 83px;
   display: flex;
   justify-content: center;
   align-items: center;
  width: 100%;
  background-color:#e4f2f4 ;
  color: #00748b;
 }

.containerAll{
  position: relative;
  width: 70%;
}

.title_container{
  color:#00637b !important; 
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 35px !important;
  text-align: center;
  margin-top: 50px !important;
  margin-bottom: 0px !important
}

.container_img_points{
  display: flex;
  align-items: flex-start;
  margin-left: 30px;
}

.container_img_woman{
  display: none;
  justify-content: flex-end;
  position: relative;
  padding-left: 150px;
}

.img_mujer{
position: absolute;
z-index: 10;
top:0px;
left:180px

}

.img_fondo{
position: absolute;
top:0px
}

.arrow_up{
  display: flex;
  justify-content: end;
  align-items: flex-end;
  height: 100px;
  padding-bottom: 20px;
  padding-right: 15px;
}

.arrow_up img{
  width: 30px;
  height:20px;
} 
.frequently_question{
  position: relative;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px !important;
}


@media (min-width: 480px) {
  .containerAll{
    width: 100%;
  }
  .frequently_question{
    margin-top: 500px;
  }

  .title_container{
    font-size: 22px;
    margin-top: -550px;
    margin-bottom: 400px;
  }
  .frequently_question{
    padding-top: 100px
  }
  .container_img_woman{
    display: block;
  }

   .img_mujer{
    width: 100%;
    height: 400px;
    top:-450px;
    left:0px
    }
    
    .img_fondo{
      width: 100%;
      height: 400px;
      top:-450px;
       left:0px;
    }
}

@media (min-width: 768px){
  .img_mujer{
    top:20px;
    left:70px;
    width: 45% ;
    height: 80%;
    }
    
    .img_fondo{
      top:20px;
    left:50px;
    width: 70% ;
    height: 80%;
    }
  .title_container{
    margin-bottom: 10px;
    color: var(--unnamed-color-00748b);
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: var(--unnamed-character-spacing-0);
    font-size: 22px;
    line-height: 20px;
  }
  
  .container_img_points {
    position: absolute;
    top: 100px;
   }

  .frequently_question{
    display: grid;
    width: 80%;
    margin: auto;
    margin-top: 0px ;
    grid-template-columns: repeat(2,1fr);
    grid-template-areas: "one woman"
                         "two woman"
                         "three woman"
                         "four four"
                         "five five"
                         "six six"
                         "seven seven";
}


.title_container{
  color: var(--unnamed-color-00748b);
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: var(--unnamed-character-spacing-0);
  padding: 16px;
  font-size: 22px;
  line-height: 20px;
}

.frequently_question p{
  text-align: left;
  color: #707070;
  line-height: 20px;
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: var(--unnamed-character-spacing-0);
  font-size: 13px;

}

  .container_img_woman{
    grid-area: woman;
    padding-left: 0px;  
  }

  .frequentlyQuestionsOne{
    grid-area: one;
    padding-top: 0px;
  }

  .frequentlyQuestionsTwo{
    grid-area: two;
  }

  .frequentlyQuestionsThree{
    grid-area: three;
  }

  .frequentlyQuestionsFour{
    grid-area: four;
  }

  .frequentlyQuestionsFive{
    grid-area: five;
  }

  .frequentlyQuestionsSix{
    grid-area: six;
  }

  .frequentlyQuestionsSeven{
    grid-area: seven;
  }
  .arrow_up img{
    display: none;
  }

}


@media (min-width: 1024px) {
  .containerAll{
    width: 1100px;
  }
  .img_mujer {
    top:20px;
    left:70px;
    width: 45% ;
    height: 80%;
 } 
 .img_fondo{
    top:20px;
    left:50px;
    width: 70% ;
    height: 80%;
 } 
.frequently_question{
   width: 90% ;
 }
}

