@media (max-width: 768px) {
.containerServicerMobile{
    position: relative;
    margin-top: 40px;
    }    

.containerTitleRow{
    position: relative;
    width: 390px;
    display: flex;
    align-items: center;
    min-height: 40px;
    border-radius: 5px;
}
.containerDetallesPlan{
    position: absolute;
    display: none;
    width: 390px;
    top: 40px;
    z-index: 10;
    border: 1px solid #707070;
    border-radius: 5px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}
.rowDown,.rowUp{
    position: absolute;
    top: -5px;
    width: 50px;
    height: 50px;
    color: white;
}


.showPlan{
   display: block;
}

.hidePlan{
    display: none;
}

.detallesPlan{
    display: flex;
    align-items: center;
    background-color: white;
 }

.paragraphDetallePlan{
    margin-left: 10px;
    margin-bottom: 0px;
}

.iconDetallePlan{
    margin-left: 10px;
    width: 15px;
    height: 15px;
}

.titlePlan{
    font-size: 20px;
    margin-top: 0px;
    padding-left: 40px;
    color: white;
}
}