/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
  .btn-volver {
   border-radius: 35px;
   background-color: #00748b;
   font-size: 14px;
   margin: 42px 0px auto;
 }
 
   #pagoOnline h4 {
     color: #00748b;
     text-align: left;
     letter-spacing: var(--unnamed-character-spacing-0);
     font-family: 'Red Hat Display', sans-serif;
     line-height: 25px;
     font-size: 25px !important;
     font-weight: bold;
   }
 
   .vr-l {
     background-color: "00748b";
     border: "1px solid #00748b";
   }
   .divider {
     width: 100%;
     margin-left: 0px !important;
     margin-right: 0px !important;
   }
 }
 /* Medium devices (tablets, 768px and up)*/
 @media (min-width: 768px) and (max-width: 991px) {
   /*Pagos section*/
 
   .btn-volver {
     border-radius: 35px;
     background-color: #00748b;
     font-size: 14px;
     margin: 55px 0px auto;
   }
 
   #pagoOnline h4 {
     color: #00748b;
     text-align: left;
     letter-spacing: var(--unnamed-character-spacing-0);
     font-family: 'Red Hat Display', sans-serif;
     line-height: 25px;
     font-size: 25px !important;
     font-weight: bold;
   }
 
   .vr-l {
     background-color: "00748b";
     border: "1px solid #00748b";
   }
   .divider {
     width: 100%;
     margin-left: 0px !important;
     margin-right: 0px !important;
   }
 }
 
 /* Large devices (desktops, 992px and up)*/
 @media (min-width: 992px) {
   /*Pagos section*/
 
   .btn-volver {
     border-radius: 35px;
     background-color: #00748b;
     font-size: 14px;
     margin: 70px 0px auto;
   }
 
   #pagoOnline h4 {
     color: #00748b;
     text-align: left;
     letter-spacing: var(--unnamed-character-spacing-0);
     font-family: 'Red Hat Display', sans-serif;
     line-height: 35px;
     font-size: 30px !important;
     font-weight: bold;
   }
 
   .vr-l {
     background-color: "00748b";
     border: "1px solid #00748b";
   }
   .divider {
     width: 100%;
     margin-left: 0px !important;
     margin-right: 0px !important;
   }
 
   /**/
 }
 