.titulo{
    color:#00637b;
    font-size:35px ;
    font-family: 'Red Hat Display', sans-serif;
    text-align: center;
}

.containerListDenuncia{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: #6e7eb9;
    font-size: 18px;
}

.containerListDenuncia p{
    color: #6e7eb9;
}

@media (max-width: 480px) {
    .titulo{
        font-size: 22px;
    }
    .containerListDenuncia{
        font-size: 16px;
    }
}

@media(max-width : 768px)
{
    .containerListDenuncia{
        display: flex;
        flex-direction: column;
    }
}