/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
  #pagoOnline h4{
    color: #00748b;
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    font-family: 'Red Hat Display', sans-serif;
    line-height: 25px;
    font-size: 25px !important;
    font-weight: bold;
  }

  .vr-l {
    background-color: "00748b";
    border: "1px solid #00748b";
  }
  .divider {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991px) {
  /*Pagos section*/

  #pagoOnline h4 {
    color: #00748b;
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    font-family: 'Red Hat Display', sans-serif;
    line-height: 25px;
    font-size: 25px !important;
    font-weight: bold;
  }

  .vr-l {
    background-color: "00748b";
    border: "1px solid #00748b";
  }
  .divider {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  /*Pagos section*/

  #pagoOnline h4 {
    color: #00748b;
    text-align: left;
    letter-spacing: var(--unnamed-character-spacing-0);
    font-family: 'Red Hat Display', sans-serif;
    line-height: 35px;
    font-size: 30px !important;
    font-weight: bold;
  }

  .vr-l {
    background-color: "00748b";
    border: "1px solid #00748b";
  }
  .divider {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #00748b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

  /**/
}
