.containerInput{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 14px;
}

.inputText,.inputSelect,.inputEmail,.inputDate,.inputNumber, .inputTextPasaporte{
    height: 40px;
    border: none;
    border-bottom: 1px solid #707070;
    color: #707070;
    margin-top: 30px
    
}
.inputNumber{
    width: 150%;
}

.inputTextPasaporte{
    width: 150%;
}

.errors{
    font-size: 13px;
    color: red;
    position: absolute;
    top: 67px;
}

.inputSelect{
    width: 73px;
    margin-right: 20px;
}

.inputDate,.inputEmail{
    width: 328px;
}

.inputEmail,.inputDate{
    width: 99%;
    margin-top: 30px;
}

.inputDate{
    margin-top: 0px;
}
.text-area{
    margin-top: 20px;
    border: none;
}

.labelDate{
    color:#707070;
    font-size: 14px;
    font-weight: normal;
    margin-top: 30px;
}

.containerDate{
    position: relative;
}
.errorsDate{
    position: absolute;
    color: red;
    top:40px;
    font-size: 13px;
}

@media (max-width: 768px) {
.input{
    background-color: #e4f2f4;
}
.inputText{
    width: 95%;
}

.inputNumber{
    width: 126%;
}
.inputEmail {
        
    width: 99%;
}
.labelDate{
    width: 99%;
}
.inputDate {
    width: 99%;
}
}

@media(max-width: 320px){
    .inputText{
        width: 90%;
    }
    .inputSelect{
      width: 90%;
      margin-right: 10px;
    }
    .inputNumber{
        width: 114%;
    }
    .inputEmail {
        
        width: 95%;
    }
    .labelDate{
        width: 95%;
    }
    .inputDate {
        width: 95%;
    }
  
}