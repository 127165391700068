.containerMain{
  margin-top: 83px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.containerAsesoramiento{
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height:617px ;
    background-color: #D2E8EB;
 
}

.img-custom-mama{
    position: absolute;
    left:10px;
    z-index: -10;
    width: 100%;
    height: 617px;

}

.mobileTitlePrimerDia{
    display: none;
}
.paragraphPrimerDiaMobile{
    display: none;
}

.titlePrimerDiaMobile{
    display: none;
}
.containerPrimerDia{
    margin-left: 440px;
}

.titlePrimerDia{
  font-family: 'Red Hat Display', sans-serif;
    font-size: 35px;
    font-weight: bold;
    color:#00638B ;
    margin-top: 10px;
  }

  .titulo-celulas {
    color: #00748b;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    font-style: italic;
    font-family: 'Red Hat Display', sans-serif;
  }

  .texto-celulas {
    font-size: 16px;
    text-align: center;
    padding-left: 4%;
    padding-right: 4%;
    color: #707070;
    font-family: 'Red Hat Display', sans-serif;
  }

  .texto-aplicacion {
    font-size: 16px;
    text-align: center;
    color: #00748b;
    font-family: 'Red Hat Display', sans-serif;
  }

  .latoL {
    font-family: 'Red Hat Display', sans-serif;
  }

  .texto-celulas-obtencion {
    font-size: 16px;
    padding-left: 4%;
    padding-right: 4%;
    color: #707070;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0;
    line-height: 26px;
  }

  .texto-celulas-valido {
    font-size: 14px;
    padding-left: 4%;
    padding-right: 4%;
    color: #707070;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0;
    line-height: 26px;
    font-style: italic;
  }

  .texto-celulas-servicios {
    font-size: 16px;
    color: #707070;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0;
    line-height: 26px;
  }

  .title-central-obtencion {
    text-align: center;
    height: 62px;
    color: var(--unnamed-color-00748b);
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: 0;
    padding: 16px;
    margin-top: 20px;
    font-weight: bold;
  }

  .container-celulasmadre {
    background-color: #ffffff;
  }

  .container-formulario {
    background-color: #e2f4f4;
  }

  .title-central-itallic {
    font-size: 12px;
    font-family: 'Red Hat Display', sans-serif;
  }

  .custom-accordion {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .custom-card {
    border: none;
    border-radius: unset !important;
    background-color: #e4f2f4;
  }

  .custom-card-inicio {
    border: none;
    border-radius: unset;
    background-color: #e4f2f4;
  }

  .custom-card-header {
    border: none;
    background-color: #e4f2f4;
    padding: 0px;
  }

  .custom-card-header-investigacion {
    border: none;
    background-color: #e4f2f4;
    padding: 0px;
    width: 105%;
  }

  .custom-card-body-inicio {
    color: #00748b;
    font-weight: 100;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 13px;
  }

  .quiero-asesorarme {
    padding: 5px 40px;
    display: block;
    margin: 0 auto;
  }

  .quiero-asesorarme-form {
    padding: 5px 40px;
    display: block;
    margin: 0 auto;
  }

  .planes-smgcells {
    font-size: 14px;
    text-align: center;
  }

  .card-header-essential {
    padding: 5px 5px;
    margin-bottom: 0;
    border-radius: 10px !important;
    background-color: #6e7eb9;
    border-bottom: none;
    height: 40px;
    width: 100%;
    padding-left: 15px;
  }

  .card-header-cyclic {
    padding: 5px 5px;
    margin-bottom: 0;
    border-radius: 10px !important;
    background-color: #e86f70;
    border-bottom: none;
    height: 40px;
    width: 100%;
    padding-left: 15px;
  }

  .card-header-promo {
    padding: 5px 5px;
    margin-bottom: 0;
    border-radius: 10px !important;
    background-color: #0f768b;
    border-bottom: none;
    height: 40px;
    width: 100%;
    padding-left: 15px;
  }

  .custom-card-essential {
    border-bottom: none !important;
  }

  .custom-btn-accordion {
    font-size: 18px;
    color: #ffffff;
    display: contents;
  }

  .custom-btn-link-h {
    font-size: 13px;
    color: #00748b;
    font-weight: bold;
    flex-grow: 0 !important;
  }
  .custom-h5 {
    vertical-align: middle;
  }

  .margen-inferior {
    margin-bottom: 80px;
  }

  .margen-inferior-80 {
    margin-bottom: 80px;
  }

  .margen-inferior-40 {
    margin-bottom: 40px;
  }

  .puntos-lateral {
    display: flex;
    float: right;
  }

  .cyclic-line {
    display: flex;
    justify-content: center;
  }

  .texto-procesos {
    font-size: 17px;
    color: #0f768b;
    font-family: 'Red Hat Display', sans-serif;
  }

  .texto-aclaraciones {
    display: block;
    margin: 0 auto;
    font-size: 14px;
    color: #000000;
    text-align: justify;
  }

  .puntos-final {
    display: flex;
    float: right;
  }

  .margen-inferior-3 {
    margin-bottom: 25px;
  }

  .margen-inferior-cyclicline {
    margin-bottom: 25px;
  }

  .carousel-item {
    display: block;
  }

  .imgProcesamiento {
    margin-left: 10px;
  }

  .margin-indicators {
    margin-bottom: -50px;
  }

  .carousel-indicators .active {
    background-color: #00748b;
    margin-right: 10px;
}

.paragraphPrimerDia{
  font-family: 'Red Hat Display', sans-serif;
    font-size: 25px;
    margin-right: 10px;
    color: #707070;
    margin-bottom: 0;

}

.containerRest{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    width: 58%;
}




@media (max-width: 1024px) {

  .containerMain{
    margin-top: 68px;
  }

.containerPrimerDia{
    width: 35%;
    right: 42%;
}
.imgFondoPagina{
    left: -61%;
    height: 1930px;
}

.containerRest{
    width: 93%;
}

.img-custom-mama{
   width: 130%; 
  left:-220px
}

}


@media (max-width: 768px) {

  .containerMain{
    margin-top: 11%;
  }

    .containerAsesoramiento{
        display: flex;
        flex-direction: column;
        height: 1300px;
        padding-top: 500px;
    }

    .img-custom-mama{
        top:0px;
        left: 0px;
         width: 130%;
         height: 30%;
    }
    .mobileTitlePrimerDia{
        display: block;
        position: absolute;
        z-index: 30;
        top: 140px;
        left: 60%;
    }

    .containerPrimerDiaParrafoMobile{
        margin-top: -150px;
        margin-bottom: 20px;
    }

    .titlePrimerDiaMobile{
        display: block;
        font-size: 22px;
        color:#00637b;
        font-family: 'Red Hat Display', sans-serif;
        margin-top: 0px;
    }

    .paragraphPrimerDiaMobile{
        display: block;
        font-family: 'Red Hat Display', sans-serif;
        font-size: 15px;
        color: #707070;
    }

    .titlePrimerDia{
        font-size: 30px;
    }
    .paragraphPrimerDia{
        font-size: 16px;
    }

    .containerRest{
        width: 100%;
    }

    .containerPrimerDia{
        display: none;
    }

    .logoPuntosCyclicLine{
        width: 40px;
        top:200px !important;
        right: 30px !important;
    }

}

@media (max-width: 480px) {
  .containerMain{
    margin-top: 64px;
  }
    .containerAsesoramiento{
        height: 1100px;
        padding-top: 350px
    }

    .mobileTitlePrimerDia{
      top: 80px;
      left: 214px;
    }

    .img-custom-mama{
        height: 20%;
    }
   


}