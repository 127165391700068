.containerLoadingForm{
   position: relative;
    z-index: 2; 
}

.containerFormAsesoramiento{
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 370;
    height: 617px !important;
    padding: 10px;
    padding-right: 25px;
    margin-left: 80px;
    border-radius: 10px;
}

.formRegistration{
    position: relative;
    padding-left: 15px;
    
}

.titleAsesoramiento{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #00637b;
    margin-top: 10px;
}

.containerDocument, .containerCodAreTelefono,.containerNameLastName{
    display: flex;
    margin-bottom: 5px;
}

.errorsRecaptcha{
    color: red;
}

.formRegistration{
    display: flex;
    flex-direction: column;
}

.containerBtnAsesoramientoInicio{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
}

.btnFormAsesoramiento{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 203px;
    height: 36px;
    background:#00748B ;
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    color:white;
    margin-top: 20px;
    border: none;
    border-radius: 50px;
    
}

.titleAsesoramientoInicio{
    font-size: 25px;
    font-weight: bold;
    color: #00637b;
    margin-top: 30px;
    font-family: 'Red Hat Display', sans-serif;
  }

@media(max-width:1024px){
    .containerFormAsesoramiento{
        margin-right : 35px;
        margin-left: 44px;
        }
        
        .containerPrimerDia {
            margin-left: 248px !important;
        }
}

@media (max-width: 768px) {
.containerFormAsesoramiento{
    height: 700px;
    margin-left: 0;
    margin-right : 0px;
    padding: 40px;
}

.titleAsesoramientoInicio{
    font-size: 25px;
    font-weight: bold;
    color: #00637b;
    margin-top: 30px;
    font-family: 'Red Hat Display', sans-serif;
  }

.containerBtnAsesoramientoInicio{
    margin-top: -30px;
}
.containerFormAsesoramiento{
    background-color: #e4f2f4;
}
.formRegistration{
    padding-left: 0px;
}

}

@media(max-width:320px){

    .containerNameLastName{
        display: flex;
        justify-content: space-around;
    }
    .containerDocument{
        display: flex;
        
    }
    .containerCodAreTelefono{
        display: flex;
    }

}