.containerCelulasMadre{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.titleTypeCelula{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #00637b;
    text-align: center;
    margin-bottom: 25px;
}

.titleCelulasMadre,.titleCelulasMadreMobile{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 35px;
    font-weight: bold;
    margin-top: 100px;
    color: #00637b;
    text-align: center;
}

.boldParagraf{
    font-weight: bold;
  }

.textCelulasMadreOne{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    font-weight : normal;
    color:#707070 ;
    margin-top: 20px;
    margin-bottom: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
}

.logoPuntosCelulasMadre{
    position: absolute;
    top:0;
    right: 0;
}

.containerCelulasHematopoyecticas,.containerCelulasMesenquimales{
    width: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerLogoGrupo{
    width: 30px;
}
.imgGrupo{
    margin-top: 60px;
}

.containerCelulasLogos{
    display: flex;
    width: 80%;
}

.titleCelulas{
    font-family: 'Red Hat Display', sans-serif;
    font-style: italic;
    font-size: 20px;
    color: #00637b;
    margin-top: 25px;
}

.titleCelulasMadreMobile{
    display: none;
}

.paragraphCelula{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    color: #707070;
}

.containerObtienen{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.titleObtienen{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: #00637b;
    margin-top: 40px;
    margin-bottom: 30px;
}

.paragraphObtienen{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    color: #707070;
    margin-bottom: 25px;
    text-align: center;
}

.logoPuntos{
    position: absolute;
    top:180px;
    left: -100px;
}


.circleImage{
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #008b9c;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.circleImage img{
    text-align: center;
}

@media (max-width: 768px) {
    .containerCelulasMadre{
        width: 100%;
    }

    .containerCelulasLogos{
        width: 100%;
    }
    
    .textCelulasMadreOne{
        margin-left: 10px;
    }
}

@media (max-width: 768px) {
    .logoPuntosCelulasMadre{
        left: 10px;
    }

    .titleCelulasMadreMobile{
        display: block;
        font-size: 30px;
    }

    .titleCelulasMadre{
        display: none;
    }

    .textCelulasMadreOne{
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .containerCelulasLogos{
        flex-direction: column;
        align-items: center;
    }

    .containerLogoGrupo{
        display: none;
    }
    .paragraphCelula {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 480px) {

.titleCelulasMadreMobile{
    margin-top: 70px;
    font-size: 25px;
    text-align: center;
}

.textCelulasMadreOne{
    width: 86%;
}

.logoPuntosCelulasMadre{
    width: 100px;
    height: 40px;
}

}

.paragraphCelula{
    margin: 10px 30px 30px;
}