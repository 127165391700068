.containerBeneficiosCriopreservar{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color:transparent;
}

.titleBeneficios{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #00748B;
    text-align: center;
}

.containerParagraphBeneficios{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 69%;
}
.paragraphBeneficios{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 30px;
    font-style: italic;
    color: #00748B;
    text-align: center;
}

.containerListCrio{
    display: flex;
    min-height: 400px;
    justify-content: center;
    flex-direction: row;
    align-items: start;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.containerListAplicacion,.containerListProceso{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoMedicoAplicacion {
    width: 240px;
    height: 200px;
}

.containerSubtitleAplicacion{
    width: 80%;
}

.containerSubtitleProceso{
    width: 80%;
}

.containerListProceso{
    width: 60%;
    padding-top: 14px;
}

.containerSubtitleAplicacionMobile{
    display: none;
}

.logoCientificoProceso{
    width: 162px;
    height: 184px;
    margin-bottom: 0px;
}

.subtitleAplicacion,.subtitleProceso{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 30px;
    color: #00748B;
    margin-left: -10px;
}

.subtitleAplicacionMobile{
    display: none;
}

.subtitleProceso{
    margin-left: 20px;
}

.subtitleAplicacionBold,.subtitleProcesoBold{
    font-weight: bold;
    font-size: 30px;
}

.listBeneficiosCriopreservar{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    color: #00748B;
}
.ContainerFondoPagina{
    position: absolute;
    top: -100px;
    z-index:-2;
}

.containerLogosFondo{
    position: relative;
}

.imgFondoFlor{
    position: absolute;
    top:-33px;
    left: 1764;
    z-index: 10;
}

.imgFondoFlorFondo{
    position: absolute;
    top: 2086;
    left: 1764;
    z-index: 10;
}

.imgFondoOvalo{
    height: 2126;
}

.listDetalles{
    font-size: 20px;
}

@media(max-width:1440px){
    .imgFondoFlor{
        position: absolute;
        top:-33px;
        left: 2019px;
        z-index: 10;
    }
    
    .imgFondoFlorFondo{
        position: absolute;
        top: 2383px;
        left: 2019px;
        z-index: 10;
    }
    
    .imgFondoOvalo{
        height: 2423px;
    }
}

@media(max-width:1024px){
    .imgFondoFlor{
        position: absolute;
        top:-33px;
        left: 1926px;
        z-index: 10;
    }
    
    .imgFondoFlorFondo{
        position: absolute;
        top: 2262px;
        left: 1921px;
        z-index: 10;
    }
    
    .imgFondoOvalo{
        height: 2299px;
    }
}

@media (max-width: 768px) {

 .containerListAplicacion,.containerListProceso{
    margin-bottom: 20px;
 }

   .titleBeneficios{
        font-size: 30px;
        margin-bottom: 40px;
    }

    .containerParagraphBeneficios{
        width: 77%;
    }

    .paragraphBeneficios{
        font-size: 20px;
    }
    .containerListCrio{
        flex-direction: column;
        align-items: center;
    }

    
    .containerSubtitleAplicacion{
        display: none;
    }

    .subtitleAplicacionMobile{
        display: block;
        color: #00748B;
        font-size: 17px;
        margin-left: 51px;
    }

    .subtitleAplicacionMobileCientifico{
        display: block;
        color: #00748B;
        font-size: 17px;
        margin-left: 51px;
    }

    .containerSubtitleAplicacionMobile{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 360px;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 0px;
    }
    .rowDown,.rowUp{
        position: absolute;
        width: 17px;
        height: 10px;
        right: 0px;
    }

    .listBeneficiosCriopreservar{
         display: none;
         width: 350px;
         background-color: white;
         font-weight: normal;
         padding-top: 20px;
         padding-bottom: 30px;
         padding-right: 20px;
    }

     .containerlistDetalles{
        position: relative;
    }
    .showAplicacionDetalleMobile{
        position: absolute;
        display: block;
        z-index: 10;
        right: -180px;
        top:-20px;
    }

    .hideAplicacionDetalleMobile{
        display: none;
    }

    .listDetalles{
        font-size: 14px;
        color: #707070;
    }

    .subtitleProceso{
        display: none;
    }

    .rowDownB,.rowUpB{
        position: absolute;
        width: 17px;
        height: 10px;
        right: 30;
        top: 8px;
    }

    .rowDownBMedico,.rowUpBMedico{
        position: absolute;
        width: 17px;
        height: 10px;
        right: 30px;
        top: 8px;
    }

    .imgFondoFlorFondo{
        top: 895px;
        left: 754px;
    }
    .imgFondoFlor{
        left: 756px;
     }
     .imgFondoOvalo{
        height: 935px;  
    }
}
@media(max-width:767px){

    .containerListProceso {
        padding-top: 30px;
    }
}

@media (max-width: 480px) {
    .paragraphBeneficios{
        font-size: 20px;
    }
    
    .imgFondoFlorFondo{
        top: 1138px;
        left: 962px;
    }
    .imgFondoFlor{
        left: 961px; 
     }
     .imgFondoOvalo{
        height: 1172px;
    }
}

@media (max-width: 320px) {
   .imgFondoOvalo{
    height: 1929px; 
    }

    .imgFondoFlorFondo{
        top: 1895px;
        left: 1616px ;
    }

    .paragraphBeneficios{
        font-size: 20px;
    }
}

@media (min-width: 768px){
    .containerListCrio{
        display: flex;
        min-height: 400px;
        justify-content: center;
        flex-direction: row !important;
        align-items: start;
        margin-top: 30px !important;
        margin-bottom:20px !important;
    }

    .containerListAplicacion{
        margin-right: 50px;;
    }

    .containerListProceso{
        margin-left: 50px;
    }
}
 @media(min-width:1560px){
    .imgFondoFlorFondo{
        display: none;
    }
    .imgFondoOvalo{
        height: 2300px;
    }
    .imgFondoFlor{
        position: absolute;
        top:-33px;
        left: 1907px;
        z-index: 10;
    }
 }