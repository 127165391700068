.linkSomePage{
  cursor: pointer;
  color:#00748b !important;
}

f.scroll-s {
  scroll-behavior: smooth;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
  .header {
    flex-grow: 4;
    background-color: #ecf4f5;
    text-align: center;
    position: "initial";
    height: 83px;
    font-size: 0.4vw;
    box-shadow: "none";
    color: #00748b;
  }

  button {
    /*margin-left: 20px;*/
    margin-left: 3px;
    border: none;
    border-radius: 5px;
    color: #00748b;
    font-size: 14px;
    overflow: hidden;
    font-family: 'Red Hat Display', sans-serif;
    background-color: transparent;
    line-height: 40px;
  }

  button:after {
    margin-left: 20px;
    border: none;
    border-radius: 5px;
    color: #00748b;
    font-size: 14px;
    overflow: hidden;
    font-family: 'Red Hat Display', sans-serif;
    background-color: transparent;

    line-height: 40px;
  }

  .navbar {
    margin-bottom: 0px;
  }
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 15px;
  }
  .navbar-default .navbar-toggle {
    background-color: #e4f2f4;
    border: none;
  }
  #menu.navbar-default {
    background-color: #e4f2f4;
  }

  .r-link {
    display: var(--rLinkDisplay, inline-flex) !important;
    font-weight: bold;
  }

  .r-link[href] {
    color: #00748b !important;
    text-decoration: var(--rLinkTextDecoration, none) !important;
    font-weight: bold;
  }
  .background-i {
    background-color: #ecf4f5 !important;
  }

  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background-color: #ecf4f5 !important;
  }
  .textMenu{
    font-size:16px;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991px) {
  .img-cells {
    width: 110px;
  }
  a,
  a:hover {
    color: white;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: var(--unnamed-character-spacing-0) !important;
    line-height: 25px;
    font-size: x-small !important;
    font-size: 10px !important;
  }

  .header {
    flex-grow: 4;
    background-color: #ecf4f5;
    padding-right: 0px;
    padding-left: 0px;
    text-align: center;
    position: "initial";
    height: 83px;
    font-size: 0.6vw;
    box-shadow: "none";
    color: #00748b;
  }

  button {
    /*margin-left: 20px;*/
    margin-left: 0px;
    border: none;
    border-radius: 5px;
    color: #00748b;
    font-size: 10px;
    overflow: hidden;
    font-family: 'Red Hat Display', sans-serif;
    background-color: transparent;
  }

  button:after {
    margin-left: 0px;
    border: none;
    border-radius: 5px;
    color: #00748b;
    font-size: 10px;
    overflow: hidden;
    font-family: 'Red Hat Display', sans-serif;
    background-color: transparent;
  }

  .ButtonR {
    font-family: 'Red Hat Display', sans-serif;
    font-size: "14px";
    margin-left: "20px";
    background: "#ffffff  0% 0% no-repeat padding-box";
    border: "1px solid #00748b";
    border-radius: "20px";
    opacity: "1";
    color: "#00748b";
    width: "80px !important";
    text-transform: "capitalize";
    height: "34px";
  }

  .ButtonR:after {
    font-size: "10px";
    margin-left: "4px";
    color: "#00748b !important";
    width: "80px !important";
    height: "34px";
  }

  .ButtonI {
    font-family: 'Red Hat Display', sans-serif;
    font-size: "14px";
    margin-left: "20px";
    background: "#00748b  0% 0% no-repeat padding-box";
    border: "1px solid #00748b";
    border-radius: "20px";
    opacity: "1";
    color: "#ffffff";
    width: "80px !important";
    text-transform: "capitalize";
    height: "34px";
  }

  .ButtonI:after {
    font-size: "10px";
    margin-left: "4px";
    color: "#ffffff !important";
    width: "80px !important";
    height: "34px";
  }

  @keyframes ripple {
    0% {
      transform: scale(0);
      opacity: 0.6;
    }
    30% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .click:not(:active):after {
    animation: ripple 2s ease-out;
  }

  .hover:hover:after {
    animation: ripple 2s ease-out;
  }

  /* fixes initial animation run, without user input, on page load.
   */
  button:after {
    visibility: hidden;
  }

  button:focus:after {
    visibility: visible;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  /*Navigation section*/

  .header {
    flex-grow: 4;
    background-color: #ecf4f5;
    text-align: center;
    position: "initial";
    height: 83px;
    font-size: 0.9vw;
    box-shadow: "none";
    color: #00748b;
  }

  button {
    border: none;
    border-radius: 5px;
    color: #00748b;
    font-size: 14px;
    overflow: hidden;
    font-family: 'Red Hat Display', sans-serif;
    background-color: transparent;
  }

  button:after {
    margin-left: 20px;
    border: none;
    border-radius: 5px;
    color: #00748b;
    font-size: 14px;
    overflow: hidden;
    font-family: 'Red Hat Display', sans-serif;
    background-color: transparent;
  }

  .ButtonR {
    font-family: 'Red Hat Display', sans-serif;
    font-size: "14px";
    margin-left: "20px";
    background: "#ffffff  0% 0% no-repeat padding-box !important";
    border: "1px solid #00748b";
    border-radius: "20px !important";
    opacity: "1";
    color: "#00748b";
    width: "111px !important";
    text-transform: "capitalize";
    height: "32px";
  }

  .ButtonR:after {
    font-size: "10px";
    margin-left: "4px";
    color: "#00748b !important";
    width: "111px !important";
    height: "32px";
  }

  .ButtonI {
    font-family: 'Red Hat Display', sans-serif;
    font-size: "14px";
    margin-left: "20px";
    background: "#00748b  0% 0% no-repeat padding-box";
    border: "1px solid #00748b";
    border-radius: "20px";
    opacity: "1";
    color: "#ffffff";
    width: "111px !important";
    text-transform: "capitalize";
    height: "32px";
  }

  .ButtonI:after {
    font-size: "10px";
    margin-left: "4px";
    color: "#ffffff !important";
    width: "111px !important";
    height: "32px";
  }

  @keyframes ripple {
    0% {
      transform: scale(0);
      opacity: 0.6;
    }
    30% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .click:not(:active):after {
    animation: ripple 2s ease-out;
  }

  .hover:hover:after {
    animation: ripple 2s ease-out;
  }

  /* fixes initial animation run, without user input, on page load.
   */
  button:after {
    visibility: hidden;
  }

  button:focus:after {
    visibility: visible;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
