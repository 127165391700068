.btnAsesorame{
    width: 341px;
    height: 66px;
    background-color: #00748B ;
    color: white;
    font-size: 24px;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    border: none;
    border-radius: 50px;
    margin-top: 60px;
    margin-bottom: 70px;
}