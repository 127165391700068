.container_question_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    color: #00637b;
    border-bottom: 1px solid #00637b;
  }
  
.container_question_title h4{
   font-family: 'Red Hat Display', sans-serif !important;
    margin-top: 10px;
    margin-left: 10px;
}
  .container_question_p{
    display: block;
    padding: 6px 12.5px 6px 12.5px ;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
   
  }
  
  .container_question_p_none{
    display: none;
  }
  
  .container_question_p p{
    text-align: justify;
    color: #707070;
    font-size: 12px;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 13px;
  }

  @media (min-width: 768px){
    .container_question_title{
      /*   pointer-events: none;
        cursor: default;
        border: none; */
        /* cursor:pointer */
        cursor: pointer;
        border-bottom: 0.5px solid #c4bfbf;
    }

    /* .flecha_accordion_mdpi_flecha_pf{
        display: none;
    } */

    .container_question_title h4{
        margin-left: 0px;
        text-align: left;
        font-family: 'Red Hat Display', sans-serif;
        letter-spacing: var(--unnamed-character-spacing-0);
        line-height: 17px;
        font-size: 18px;
      }
 
    .container_question_p{
        display: block;
    }

    .container_question_p_none{
        display: none;
    }

    .container_question_p_none p {
        text-align: left;
        color: #707070;
        line-height: 20px !important;
        font-family: 'Red Hat Display', sans-serif;
        letter-spacing: var(--unnamed-character-spacing-0);
        font-size: 13px;
    }
  }

/* @media (min-width: 992px) {

  .container_question_title h4{
    line-height: 35px;
    font-size: 30px !important;
    font-weight: bold
  }

  .container_question_p_none p {
    line-height: 30px;
    font-family: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-bold) 18px/18px var(--unnamed-font-family-lato);
    font-size: 18px;
  }
}
   */
