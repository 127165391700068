/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .btn-ingresar {
    width: 50%;
    height: 38px;
    border-radius: 35px;
    background-color: #00748b;
    font-size: 14px;
    margin: 20px 0px auto;
  }

  .olvido-clave {
    font-size: 16px;
    color: #00748b;
  }

  .div-no-registrado {
    color: #00748b;
    margin-top: 35px;
  }

  .text-no-registrado {
    font-size: 16px;
    color: #00748b;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  /*Ingresar section*/

  .logo-correo {
    margin-top: 20px;
    margin-left: 40px;
    height: 40px;
  }

  .top-section {
    background-color: var(--yellow-correo);
    height: var(--navbar-height);
    padding: 0 !important;
    margin: 0 !important;
  }

  .form-fondo {
    /*background: white;
    top: 108px;
    left: 1159px;
    width: 440px;
    height: auto;
    float: right;*/

    background: white;
    position: absolute;
    left: 80%;
    top: 40%;
    height: 200px;
    margin: -100px 0 0 -200px;
    max-width: 330px;
  }

  .form-fondo-i {
    top: 88%;
    position: absolute;
    left: 67%;
  }
  .btn-ii {
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #fff;
    background-image: linear-gradient(to right, #00758d 0%, #00758d 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 0;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    background-color: #00758d;
    border-color: #00758d;
    box-shadow: none;
    color: #fff;
    width: 200px;
    font-size: 16px !important;
  }

  .btn-ii:hover {
    background: #00748b 0% 0% no-repeat padding-box;
    border: 1px solid #00748b;
    color: #ffffff;
    font-size: 13px;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: var(--unnamed-character-spacing-0);
    background-image: linear-gradient(to right, #00758d 0%, #00758d 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 0;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    background-color: #00758d;
    border-color: #00758d;
    box-shadow: none;
    color: #fff;
    width: 200px;
    font-size: 16px !important;
  }

  @media (max-width: 991px) {
    .login-section-wrapper {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  @media (max-width: 575px) {
    .login-section-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 100vh;
    }
  }

  .login-wrapper {
    width: 300px;
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media (max-width: 575px) {
    .login-wrapper {
      width: 100%;
    }

    .form {
      max-width: 330px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      margin-top: 30px;
    }

    .form .form__custom-button {
      margin-top: 50px;
    }
  }
  .login-wrapper label {
    font-size: 14px;
    font-weight: bold;
    color: #b0adad;
  }
  .login-wrapper .form-control {
    border: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    padding: 9px 5px;
    min-height: 40px;
    font-size: 18px;
    font-weight: normal;
  }
  .login-wrapper .form-control::-webkit-input-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control::-moz-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control:-ms-input-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control::-ms-input-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control::placeholder {
    color: #b0adad;
  }
  .login-wrapper .login-btn {
    padding: 13px 20px;
    background-color: #fdbb28;
    border-radius: 0;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 14px;
  }
  .login-wrapper .login-btn:hover {
    border: 1px solid #fdbb28;
    background-color: #fff;
    color: #fdbb28;
  }
  .login-wrapper a.forgot-password-link {
    color: #00758d;
    font-size: 14px;
    text-decoration: underline;
    display: inline-block;
    margin-bottom: 54px;
  }
  strong,
  b {
    font-weight: 700;
    color: #00748b;
  }
  .b-1 {
    font-weight: bold;
    color: inherit;

    font-size: 18px !important;
    font-weight: bold;

    color: #707070 !important;
    text-align: left;

    font-family: 'Red Hat Display', sans-serif;
  }

  .f {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 95%;
    padding: 1rem;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  /*Ingresar section*/
  .form-fondo-i {
    top: 91%;
    position: absolute;
    left: 72%;
  }

  .logo-correo {
    margin-top: 20px;
    margin-left: 40px;
    height: 40px;
  }

  .top-section {
    background-color: var(--yellow-correo);
    height: var(--navbar-height);
    padding: 0 !important;
    margin: 0 !important;
  }

  .form-fondo {
    /*background: white;
    top: 108px;
    left: 1159px;
    width: 440px;
    height: auto;
    float: right;*/

    background: white;
    position: absolute;
    left: 80%;
    top: 40%;
    width: 400px;
    height: 200px;
    margin: -100px 0 0 -200px;
  }

  .btn-ii {
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: #fff;
    background-image: linear-gradient(to right, #00758d 0%, #00758d 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 0;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    background-color: #00758d;
    border-color: #00758d;
    box-shadow: none;
    color: #fff;
    width: 200px;
    font-size: 16px !important;
  }

  .btn-ii:hover {
    background: #00748b 0% 0% no-repeat padding-box;
    border: 1px solid #00748b;
    color: #ffffff;
    font-size: 13px;
    font-family: 'Red Hat Display', sans-serif;
    letter-spacing: var(--unnamed-character-spacing-0);
    background-image: linear-gradient(to right, #00758d 0%, #00758d 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 0;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    background-color: #00758d;
    border-color: #00758d;
    box-shadow: none;
    color: #fff;
    width: 200px;
    font-size: 16px !important;
  }

  @media (max-width: 991px) {
    .login-section-wrapper {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  @media (max-width: 575px) {
    .login-section-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 100vh;
    }
  }

  .login-wrapper {
    width: 300px;
    max-width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media (max-width: 575px) {
    .login-wrapper {
      width: 100%;
    }

    .form {
      max-width: 330px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      background: white;
      padding: 20px;
      margin-top: 30px;
    }

    .form .form__custom-button {
      margin-top: 50px;
    }
  }
  .login-wrapper label {
    font-size: 14px;
    font-weight: bold;
    color: #b0adad;
  }
  .login-wrapper .form-control {
    border: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    padding: 9px 5px;
    min-height: 40px;
    font-size: 18px;
    font-weight: normal;
  }
  .login-wrapper .form-control::-webkit-input-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control::-moz-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control:-ms-input-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control::-ms-input-placeholder {
    color: #b0adad;
  }
  .login-wrapper .form-control::placeholder {
    color: #b0adad;
  }
  .login-wrapper .login-btn {
    padding: 13px 20px;
    background-color: #fdbb28;
    border-radius: 0;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 14px;
  }
  .login-wrapper .login-btn:hover {
    border: 1px solid #fdbb28;
    background-color: #fff;
    color: #fdbb28;
  }
  .login-wrapper a.forgot-password-link {
    color: #00758d;
    font-size: 14px;
    text-decoration: underline;
    display: inline-block;
    margin-bottom: 54px;
  }
  strong,
  b {
    font-weight: 700;
    color: #00748b;
  }
  .b-1 {
    font-weight: bold;
    color: inherit;

    font-size: 24px !important;
    font-weight: bold;

    color: #707070 !important;
    text-align: left;

    font-family: 'Red Hat Display', sans-serif;
  }

  .f {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 95%;
    padding: 1rem;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}