 .container_imagen{
   height: 57px;
   padding-left: 100px;
 }

 .container_imagen img{
  height: 57px;
 }

 .mainTitle{
  margin-top: 83px;
  text-align: center;
}

.mainTitle, .titleProfessionals{
  font-family: 'Red Hat Display', sans-serif;
  font-weight: bold;
  color: #00748b;
}
.gridText{
  display: grid;
  grid-template-areas: "one two"
                        "three four";
  grid-gap: 30px;
  width: 95%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 240px;
  font-size: 16px ;
  text-align: left;
  font-family: 'Red Hat Display', sans-serif;
}
.gridTextOne{
  grid-area: one;
}

.gridTextTwo{
  grid-area: two;
  padding-right: 30px;
}

.gridTextThree{
  grid-area: three;
  padding-right: 30px;
}

.gridTextFour{
  grid-area: four;
}

.gridTextOne p, .gridTextTwo p, .gridTextThree p{
    color: #3c3c3b;
}
.gridTextTwo p{
  padding-right: 10px;
}

.gridTextFour li{
  padding-right: 50px;
}

.gridTextOne p, .gridTextTwo p, .gridTextThree p, .gridTextFour li{
  font: var(--unnamed-font-weight-bold) 18px/18px
  var(--unnamed-font-family-lato);
letter-spacing: var(--unnamed-character-spacing-0);
font-weight: normal;
line-height: 26px;
}


.boldParagraf{
  font-weight: bold;
}

.titleProfessionals{
  margin-top: 0;

}

.listprofessionals{
  margin-left: 0;
  padding-left: 20px;
  color: #00748b;
}


@media (max-width: 400px) {
  .gridTextThree{
    padding-right: 0;
  }

  .gridTextTwo p{
    padding-right: 0;
  }
  .gridTextOne p, .gridTextTwo p, .gridTextThree p, .gridTextFour li{
    font-size: 16px;
  }

  .mainTitle{
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .container_imagen{
    display: none;
  }
  .gridText{
    grid-template-areas: "one one"
                          "three three"
                          "two two"
                          "four four";
    width: 90%;
    margin-top: 50px;
    margin-bottom: 97px;
  }  

  .gridTextOne p, .gridTextTwo p, .gridTextThree p {
    text-align: justify;
  }
  
  .gridTextOne p, .gridTextTwo p, .gridTextThree p, .gridTextFour li{
    font-size: 16px;
  }

  .gridTextFour li{
    padding-right: 100px;
  }

  .mainTitle{
    margin-top: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container_imagen{
    padding-left: 0px;
  }
  .gridText{
    grid-gap: 25px;
    width: 98%;
    padding-right: 0;
    margin-bottom: 128px;
    margin-left: 12px;
  }
  .gridTextOne, .gridTextTwo, .gridTextThree, .gridTextFour{
    width: 100%;
  }

  .gridTextOne p, .gridTextTwo p, .gridTextThree p, .gridTextFour li{
    font-size: 16px;
  }
}

@media (min-width: 830px) and (max-width: 1024px) {
  .gridText{
    width: 98%;
  }

  .gridTextOne p, .gridTextTwo p, .gridTextThree p{
    color: #3c3c3b;
  }
}

@media (min-width: 1025px){
    .gridText{
      width: 65%;
      padding-left: 30px;
      padding-right: 11;
    }

    .gridTextTwo {
      padding-left: 34px;
    }

    .gridTextFour {
      padding-left: 30px;
    }

    .gridTextTwo p{
      padding-right: 20px;
    }
}  
 