.serviceTitle {
    position: relative;
    height: 5rem;
    border-radius: 10px 10px 150% 150% / 10px 10px 120% 120%;
    text-align: center;
    margin-bottom: 10px;
    padding: 5px;
}
.serviceTitle::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5.5rem;
    border-radius: 10px 10px 150% 150% / 10px 10px 120% 120%;
    border-bottom: 0.3rem dotted #BCDFE5;
}
.serviceTitle h2{
    margin: 0;
    padding-top: 0.2rem;
    font-size: 1.3rem;
    font-weight: 350;
}

.serviceTitle .hermanosTitle, .serviceTitle .cyclicTitle {
    font-size: 1rem;
}

.serviceBody ul {
    list-style: none;
    padding: 0;
}


.serviceBody ul li {
    height: 3rem;
    font-size: 1.0rem;
    text-align: center;
    vertical-align: bottom;
    margin-left: 2rem;
    margin-right: 2rem;
    border-bottom: 1px solid rgba(148, 148, 148, 0.438);
}

.descBody ul li{
    text-align: left;
    vertical-align: middle;
    margin-left: 2rem;
    margin-right: 2rem;
    color: #008397;
}


.descBody ul li{
    display: flex;
    align-items: center;
}
.descBodyPlanes ul li{
    display: flex;
    justify-content: center;
    align-items: center;
}

.serviceBody:first-child ul li {
    text-align: left;
}

.serviceHead {
    height: 5rem;
    padding: 5px;
    margin-bottom: 10px;
}
.descHead {
    position: relative;


}

.descFlowers {
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 20px;
    background-image: url('../flowers.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 92%;
    height: 60%;
}

.serviceFooter {
    height: 1.5rem;
    border-radius: 85% 50% 10px 10px / 50% 50% 10px 10px;
}