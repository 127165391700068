f.scroll-s {
    scroll-behavior: smooth;
}

body{
    color: #232323;
    line-height: 1.5;
    
}
p{
    font-family: 'Red Hat Display', sans-serif;
    font-size: 16px;
}
.color-font-item{
    color:#232323;
}   
.contenedor{
    margin-top: 50px;
    margin-bottom: 50px;
}
h1{
    font-size: 60px;
    font-weight: 600;
    font-family: 'Red Hat Display', sans-serif;
    color: #00758d;
}

.margen{
    margin-left: 10px;
    display: inline-block;
}
.parrafo{
    margin-bottom: 50px;
}
.gestion-denuncias{
    margin-bottom: 60px;
    margin-top: 20px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
  
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991px) {
   
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}
